import React, { Fragment } from "react";
import verhnjaGubaWoman from "../src/images/verhnjaGubaWoman.jpg";
import pahvuWoman from "../src/images/pahvuWoman.jpg";
import nogiWoman from "../src/images/nogiWoman.jpg";
import faceMen from "../src/images/faceMen.jpg";
import bikiniMen from "../src/images/bikiniMen.jpg";
import pahvuMen from "../src/images/pahvuMen.jpg";
import { Link } from "react-router-dom";
import {
  Grid,
  IconButton,
  Container,
  Paper,
  Typography,
  Button,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { AiOutlineMinusCircle } from "react-icons/ai";
import { connect } from "react-redux";
import { productQuantity, clearProducts } from "../src/actions/productQuantity";
import { BsTrash } from "react-icons/bs";
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1440,
    flexFlow: "row wrap",
    justifyContent: "space-around",
    background: "#fff",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      margin: "0 auto",
      flexFlow: "column",
    },
  },
  typoHeading: {
    textAlign: "center",
  },
  leftContainer: {
    background: "#fafafa",
    maxWidth: 1050,
    display: "flex",
    flexFlow: "row wrap",
    alignItems: "center",
    justifyContent: "flex-start",
    borderRadius: "20px",
    [theme.breakpoints.down("xs")]: {
      flexFlow: "column wrap",
      margin: "20px auto",
      maxWidth: "100%",
      display: "flex",
    },
  },
  rightContainer: {
    textAlign: "center",
    // height: 450,
    height: "100%",
    width: 300,
    borderRadius: "20px",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
      flexFlow: "column wrap",
      margin: "20px auto",
    },
    [theme.breakpoints.down("lg")]: {
      margin: "20px auto",
    },
  },
  rootPaper: {
    border: "none",
    padding: "1rem",
    maxWidth: 1050,
    minWidth: 1000,
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      flexFlow: "column wrap",
      height: "100%",
      padding: "1rem",
      minWidth: 330,
      justifyContent: "center",
      width: "100%",
      // margin: "15px auto",
      display: "flex",
      // border: "0.4px solid #f44336",
      borderRadius: 20,
    },
  },
  imgItem: {
    maxWidth: 170,
    marginLeft: 20,
    height: 150,
    [theme.breakpoints.down("xs")]: {
      flexFlow: "column wrap",
      marginLeft: 0,
      margin: 0,
      padding: 0,
      height: "100%",
      maxWidth: "80%",
    },
  },
  nameItem: {
    fontSize: 20,
    fontWeight: 400,
    margin: "0 auto",
    textAlign: "center",
    color: "#333",
    width: 260,
    [theme.breakpoints.down("xs")]: {
      flexFlow: "column wrap",
      textAlign: "center",
      margin: "10px auto",
      fontSize: 26,
    },
  },
  containerItem: {
    display: "flex",
    margin: "0 auto",
    alignItems: "center",
    flexFlow: "row wrap",
    [theme.breakpoints.down("xs")]: {
      // margin: "-40px auto",
      flexFlow: "column wrap",
    },
  },
  priceItem: {
    color: "#444",
    color: [theme.palette.text.primary],
    fontSize: 22,
    fontWeight: 600,
    [theme.breakpoints.down("xs")]: {
      fontSize: 26,
      display: "flex",
      justifyContent: "center",
    },
  },
  gridLeftContainer: {
    display: "flex",
    alignItems: "center",
  },
  quantityItem: {
    minWidth: 240,
    maxWidth: "100%",
    display: "flex",
    marginLeft: 15,
    padding: "auto 0",
    alignItems: "center",
    fontSize: 24,
    fontWeight: 400,
    [theme.breakpoints.down("xs")]: {
      alignItems: "center",
      marginLeft: 0,
      padding: 0,
      marginTop: 10,
    },
  },
  removeItems: {
    alignItems: "center",
    maxWidth: 30,
    marginLeft: 15,
    display: "flex",
    color: [theme.palette.primary.main],
    fontSize: 28,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
      maxWidth: 40,
    },
  },
  totalItem: {
    fontSize: 20,
    display: "flex",
    marginLeft: 20,
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 20,
      fontSize: 30,
    },
  },
  trashIcon: {
    display: "flex",
    alignItems: "center",
    color: [theme.palette.primary.main],
    fontSize: 20,
    margin: "auto 8px",
    [theme.breakpoints.down("xs")]: {
      fontSize: 30,
      alignItems: "center",
      display: "flex",
      alignContent: "center",
      margin: "0 auto",
      flexFlow: "column wrap",
    },
  },
  trashIcon2: {
    margin: "auto 8px",
    display: "flex",
    alignItems: "center",
    color: [theme.palette.primary.main],
    fontSize: 20,
    [theme.breakpoints.down("xs")]: {
      fontSize: 30,
      alignItems: "center",
      display: "flex",
      alignContent: "center",
      flexFlow: "column wrap",
    },
  },
  btnShop: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "30px auto",
    width: 350,
    fontSize: 20,
    height: 50,
    borderRadius: 15,
    textDecoration: "none",
    [theme.breakpoints.down("xs")]: {
      maxWidth: 300,
      padding: "1rem",
    },
  },
  btnShop2: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 16,
    borderRadius: 15,
    margin: "30px auto",
    [theme.breakpoints.down("xs")]: {},
  },
  totalCost: {
    display: "flex",
    padding: "1.8rem",
    margin: "30px auto",
    justifyContent: "center",
    fontSize: 32,
    color: "#333",
    [theme.breakpoints.down("xs")]: {},
  },
  gridContainer: {
    alignItems: "center",
    margin: "0 auto",
  },
}));
function Cart({ basketProps, productQuantity, clearProducts }) {
  const classes = useStyles();
  console.log(basketProps);

  let productsInCart = [];

  Object.keys(basketProps.products).forEach(function (item) {
    console.log(item);
    console.log(basketProps.products[item].inCart);
    if (basketProps.products[item].inCart) {
      productsInCart.push(basketProps.products[item]);
    }
    console.log(productsInCart); //product log
  });

  // const productImages = [
  //   verhnjaGubaWoman,
  //   pahvuWoman,
  //   nogiWoman,
  //   faceMen,
  //   bikiniMen,
  //   pahvuMen,
  // ];

  const productImages = (product) => {
    if (product.tagName === "verhnjaGubaWoman") {
      return verhnjaGubaWoman;
    } else if (product.tagName === "pahvuWoman") {
      return pahvuWoman;
    } else if (product.tagName === "nogiWoman") {
      return nogiWoman;
    } else if (product.tagName === "faceMen") {
      return faceMen;
    } else if (product.tagName === "bikiniMen") {
      return bikiniMen;
    } else if (product.tagName === "pahvuMen") {
      return pahvuMen;
    }
  };

  productsInCart = productsInCart.map((product, index) => {
    console.log("My product is: ");
    console.log(product);

    return (
      <Fragment key={index}>
        <div className={classes.rootPaper}>
          <div className={classes.containerItem}>
            <img
              className={classes.imgItem}
              src={productImages(product)}
              alt="images"
            />
            <div className={classes.nameItem}>{product.title}</div>
            <div className={classes.priceItem}>{product.price},00 грн.</div>
            <div className={classes.quantityItem}>
              <IconButton
                className={classes.trashIcon}
                onClick={() => productQuantity("decrease", product.tagName)}
              >
                <AiOutlineMinusCircle />
              </IconButton>
              {product.numbers}
              <IconButton
                className={classes.trashIcon2}
                onClick={() => productQuantity("increase", product.tagName)}
              >
                <AiOutlinePlusCircle />
              </IconButton>

              <div className={classes.totalItem}>
                {product.numbers * product.price},00 грн.
              </div>
            </div>
            <IconButton
              className={classes.trashIcon}
              onClick={() => clearProducts(product.tagName)}
            >
              <BsTrash />
            </IconButton>
          </div>
        </div>
      </Fragment>
    );
  });

  return (
    <>
      <Container className={classes.root}>
        <Grid container className={classes.gridContainer}>
          <Grid item xs className={classes.gridLeftContainer}>
            <Paper elevation={6} className={classes.leftContainer}>
              {productsInCart}
              <Link to="/" className={classes.btnShop}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.btnShop}
                >
                  Продовжити покупки
                </Button>
              </Link>
            </Paper>
          </Grid>
          <Paper elevation={6} className={classes.rightContainer}>
            <Typography className={classes.totalCost} variant="h4">
              Всього: {basketProps.cartCost} грн.
            </Typography>
            <Button
              className={classes.btnShop2}
              variant="contained"
              color="primary"
            >
              Оформити замовлення
            </Button>
          </Paper>
        </Grid>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({
  basketProps: state.basketState,
});

export default connect(mapStateToProps, { productQuantity, clearProducts })(
  Cart
);
