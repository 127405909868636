import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Container, Grid, Typography } from "@material-ui/core";
import styles from "../src/styles/Home.module.css";
import clsx from "clsx";
import ProductFlip from "./components/ProductFlip";
import ContactBlock from "./components/ContactBlock";
import GradeIcon from "@material-ui/icons/Grade";
import StoreMallDirectoryOutlinedIcon from "@material-ui/icons/StoreMallDirectoryOutlined";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import ContactForm from "./components/ContactForm";

const useStyles = makeStyles((theme) => ({
  mainHeadingCert: {
    marginTop: 30,
    textAlign: "center",
    fontSize: 28,
    color: "#333",
  },
  mainHeadingSubCert: {
    fontSize: 28,
    textAlign: "center",
    color: "#333",
  },
  cert30: {
    display: "flex",
    flexFlow: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("sm")]: {
      flexFlow: "column",
    },
  },
  cert30LeftContainer: {
    maxWidth: "50%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  cert30RightContainer: {
    display: "flex",
    maxWidth: "50%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  certButton: {
    display: "flex",
    margin: "0 auto",
    width: 320,
    borderRadius: 15,
    background: [theme.palette.primary.main],
    "&:hover": {
      background: [theme.palette.primary.dark],
    },
    height: 70,
    justifyContent: "center",
    fontSize: 18,
  },
}));

const Home = (props) => {
  const classes = useStyles();

  return (
    <Container>
      <div className={clsx(styles.row, styles.actionSection)}>
        <div id="certs" className={clsx(styles.col4, styles.actionTextSection)}>
          <div className={styles.actionTitle}>
            <span className={styles.h1}>
              Лазерна
              <br />
              епіляція
            </span>
            <span className={clsx(styles.actionBadge, styles.mt3, styles.mb3)}>
              ЗІ ЗНИЖКОЮ
            </span>
            <span className={clsx(styles.actionPrice, styles.priceNewOld)}>
              <span className={styles.colorRed}>-30%</span>
              {/* <i className={styles.priceRed}>970</i> */}
            </span>
          </div>
        </div>
        <div className={clsx(styles.col6, styles.actionImageSection)}>
          <img
            width="500px"
            height="545px"
            src={"images/22222.jpg"}
            alt="akc"
          />
        </div>
        <Grid className={clsx(styles.col4, styles.actionFormContainer)}>
          <div className={styles.col12}>
            <div className={styles.col12}>
              <ContactForm />
              <div className={clsx(styles.col12, styles.actionInfo)}>
                <i>
                  Отримайте безкоштовну консультацію та знижку на лазерну
                  епіляцію «Золотий Стандарт» на лазері LightSheer ET або Duet
                  (з вакуумною насадкою). Знижка діэ до 31.07.2021 р.
                </i>
              </div>
            </div>
          </div>
        </Grid>
      </div>
      <Typography className={classes.mainHeadingCert} id="certs">
        Сертифікати на лазерну епіляцію "Золотий Стандарт"
      </Typography>
      <Typography className={classes.mainHeadingSubCert}>
        <span style={{ marginBottom: "10px", color: "#F90224" }}>
          зі знижкою -30% &nbsp;
        </span>
        до 31.07.2021р.
      </Typography>
      <ProductFlip />
      <Typography align="center" variant="h2">
        Наші переваги
      </Typography>
      <div>
        <Grid
          item
          style={{
            display: "flex",
            flexFlow: "row wrap",
            justifyContent: "space-around",
            margin: "0 auto",
          }}
        >
          <div className={styles.polyContainer}>
            <div className={styles.polyBgImg}>
              <span className={styles.polySvg}>
                <GradeIcon fontSize="large" />
                <GradeIcon style={{ fontSize: "3rem" }} />
                <GradeIcon fontSize="large" />
              </span>
              <Typography variant="h6" className={styles.polyText}>
                Ліцензія та акредитація
                <br />
                <b>1 категорії</b>
              </Typography>
            </div>
          </div>

          <div className={styles.polyContainer}>
            <div className={styles.polyBgImg}>
              <span className={styles.polySvg}>
                <StoreMallDirectoryOutlinedIcon fontSize="large" />
              </span>
              <Typography variant="h6" className={styles.polyText}>
                <b>63 центри </b>
                <br /> на территорії всієї України
              </Typography>
            </div>
          </div>

          <div className={styles.polyContainer}>
            <div className={styles.polyBgImg}>
              <span className={styles.polySvg}>
                <PeopleAltOutlinedIcon fontSize="large" />
              </span>
              <Typography variant="h6" className={styles.polyText}>
                Більше <b>1 мільйона</b> <br />
                задоволених клієнтів
              </Typography>
            </div>
          </div>
        </Grid>
      </div>
      <div className={classes.cert30}>
        <div className={classes.cert30LeftContainer} style={{}}>
          <Typography
            style={{
              fontWeight: 600,
              fontSize: 22,
              textAlign: "center",
            }}
          >
            ОТРИМАЙТЕ ЕЛЕКТРОННИЙ СЕРТИФІКАТ
          </Typography>
          <Typography
            style={{
              fontWeight: 600,
              fontSize: 22,
              textAlign: "center",
              color: "#E30000",
            }}
            paragraph
          >
            НА ЗНИЖКУ 30%
          </Typography>
          <Typography
            style={{
              fontSize: 20,
              color: "#444",
              textAlign: "center",
            }}
            paragraph
          >
            Сертифікат діє на 1 візит <br />
            На всі повні зони зроблені в цей візит <br />
            Діє на епіляцію «Золотий Стандарт» <br />
            на лазері ЕТ та Duet (вакуумна епіляція) <br />
          </Typography>
          <Typography
            style={{
              fontSize: 20,
              letterSpacing: "0.25",
              textAlign: "center",
              color: "#444",
            }}
            paragraph
          >
            Сертифікат діє до 31.07.2021р.
          </Typography>
          <Button className={classes.certButton}>
            <a
              style={{
                textDecoration: "none",
                color: "#fff",
                margin: "0 auto",
                display: "flex",
                alignItems: "center",
              }}
              data-role="none"
              href="#certs"
            >
              Отримати сертифікат
              <br /> на знижку
            </a>
          </Button>
        </div>
        <img
          className={classes.cert30RightContainer}
          src={"images/Cert_30.jpg"}
          alt="30%"
        />
      </div>
      <ContactBlock />
    </Container>
  );
};
export default Home;
