import React from "react";
import * as Yup from "yup";
import { withFormik, Form } from "formik";
import { withStyles } from "@material-ui/core";
import axios from "../axios-orders";
import styles from "../styles/Home.module.css";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Button, InputAdornment, MenuItem } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  buttonLabel: {
    color: "#fff",
  },
}));

const FormCon = ({
  values,
  touched,
  errors,
  handleReset,
  isSubmitting,
  handleChange,
  handleBlur,
  handleSubmit,
  // classes,
  id,
}) => {
  const classes = useStyles();
  return (
    <Form className={clsx(styles.actionForm)} onSubmit={handleSubmit}>
      <div className={styles.formHeader}>
        <span>
          Отримати знижку -30% <br />
          {/* <small>Акція діє до 15.07.2021</small> */}
        </span>
      </div>
      <div className={styles.mt3}>
        <div className={styles.formBody}>
          <TextField
            id="firstName"
            label="Ваше ім'я"
            placeholder="Олена"
            type="text"
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.firstName ? errors.firstName : ""}
            error={touched.firstName && Boolean(errors.firstName)}
            margin="dense"
            variant="outlined"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <span className={classes.icon}>
                    <i className="far fa-user" />
                  </span>
                </InputAdornment>
              ),
              classes: {
                input: classes.input,
              },
            }}
          />
          <TextField
            id="phoneNumber"
            label="Номер телефону"
            placeholder="(068)-123-456-78"
            // type="number"
            value={values.phoneNumber}
            onChange={handleChange}
            onInput={values.maxLengthCheck}
            onBlur={handleBlur}
            helperText={touched.phoneNumber ? errors.phoneNumber : ""}
            error={touched.phoneNumber && Boolean(errors.phoneNumber)}
            margin="dense"
            variant="outlined"
            fullWidth
            InputProps={{
              classes: {
                input: classes.input,
              },
              endAdornment: (
                <InputAdornment position="end">
                  <span className={classes.icon}>
                    <i fontSize="48px" className="far fa-star" />
                  </span>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            variant="outlined"
            name="citiesList"
            id={id}
            select
            margin="dense"
            fullWidth
            label="Оберіть ваш центр"
            value={values.citiesList}
            onChange={handleChange}
            error={touched.citiesList && Boolean(errors.citiesList)}
            helperText={touched.citiesList ? errors.citiesList : ""}
          >
            <MenuItem key={"КИЇВ"} value={"КИЇВ"}>
              КИЇВ
            </MenuItem>
            <MenuItem key={id} value={"ХАРКІВ"}>
              ХАРКІВ
            </MenuItem>
            <MenuItem key={id} value={"ДНІПРО"}>
              ДНІПРО
            </MenuItem>
          </TextField>
          <Button
            classes={{ label: classes.buttonLabel }}
            className={clsx(styles.buttonLabel)}
            type="submit"
            variant="contained"
            disabled={isSubmitting}
          >
            Відправити заявку
          </Button>
          {/* <Button onClick={handleReset}>Очистить</Button> */}
        </div>
      </div>
    </Form>
  );
};
const FormInput = withFormik({
  mapPropsToValues: ({ firstName, citiesList, phoneNumber }) => {
    return {
      firstName: firstName || "",
      phoneNumber: phoneNumber || "",
      citiesList: citiesList || "",
    };
  },
  validationSchema: Yup.object().shape({
    firstName: Yup.string()
      .required("")
      .required("Це поле є обов'язковим для заповнення"),
    citiesList: Yup.string()
      .required("Выберите Ваш город")
      .required("Це поле є обов'язковим для заповнення"),
    phoneNumber: Yup.string()
      .required("Введите правильный номер")
      .required("Це поле є обов'язковим для заповнення"),
  }),

  handleSubmit: (values, { setSubmitting }) => {
    setTimeout(() => {
      axios
        .post("/orders.json", values)
        .then((response) => console.log(response))
        .catch((error) => console.log(error));
      setSubmitting(true);
      alert(JSON.stringify(values, null, 2));
      console.log("Cообщение отправлено");
    }, 1000);
  },
})(FormCon);

export default withStyles(styles)(FormInput);
