import React from "react";
import { Box, Paper } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import styles from "../src/styles/Delivery.module.css";

const Delivery = () => {
  return (
    <Box className={styles.deliveryContainer}>
      <Typography paragraph variant="h2">
        Оплата і доставка сертифікату
      </Typography>

      <Typography className={styles.heading} paragraph variant="h4">
        Шановні покупці!
      </Typography>
      <Typography className={styles.subHeading} paragraph variant="h5">
        Ми пропонуємо Вам кілька варіантів оплати і доставки сертифікатів.
      </Typography>
      <Typography className={styles.subHeading} variant="h5">
        Будь ласка, оберіть зручний для вас спосіб:
      </Typography>
      <Paper className={styles.PaperContainer} elevation={6}>
        <Typography className={styles.PaperMetods} variant="h4">
          Електронний сертифікат:
        </Typography>
        <Typography variant="body1">
          При оплаті сертифікату на сайті, процедура зі знижкою потрапляє до
          нашої системи. Для того, щоб використати цю оплату, достатньо
          записатись на візит та прийти в Центр. Фізичний або електронний
          сертифікат мати не обов’язково. Якщо Ви залишили заявку на сертифікат
          на знижку, знижка фіксується в Вашій картці після дзвінка та
          підтвердження оператором. Сертифікат може бути відправлено Вам на
          електронну адресу, за Вашим бажанням, але для отримання знижки, його
          наявність необов’язково. Достатньо сказати адміністратору, що Ви
          залишили заявку та маєте знижку. Для отримання знижки обов’язково
          необхідно залишити заявку на сайті, та підтвердження оператором.
          Термін дії сертифікату на знижку відповідає тому, який було вказано на
          головній сторінці сайту в момент відправлення заявки. Термін дії
          сертифікату на послугу при повній оплаті на сайті – 2 роки з моменту
          оплати.
        </Typography>
        <ul>
          <Typography variant="h6" color="primary">
            Способи оплати при самовивозі:
          </Typography>
          <li>
            <Typography variant="body1">Готівкою в Центрі</Typography>
          </li>
          <li>
            <Typography variant="body1">Безготівкова оплата</Typography>
          </li>
          <li>
            <Typography variant="body1">
              Картою Visa і MasterCard (оплата на сайті через систему
              "Wayforpay")
            </Typography>
          </li>
        </ul>
      </Paper>
      <Paper className={styles.PaperContainer} elevation={6}>
        <Typography className={styles.PaperMetods} variant="h4">
          Самовивіз:
        </Typography>
        <Typography variant="body1">
          Ви можете забрати сертифікат в будь-якому Центрі «Лазерхауз», а також.
          Для цього необхідно оформити замовлення на сайті. Коли ваш сертифікат
          буде готовий до видачі, з вами зв'яжеться наш адміністратор.
        </Typography>
        <ul>
          <Typography variant="h6" color="primary">
            Способи оплати при самовивозі:
          </Typography>
          <li>
            <Typography variant="body1">Готівкою в Центрі</Typography>
          </li>
          <li>
            <Typography variant="body1">Безготівкова оплата</Typography>
          </li>
          <li>
            <Typography variant="body1">
              Картою Visa і MasterCard (оплата на сайті через систему
              "Wayforpay")
            </Typography>
          </li>
        </ul>
      </Paper>
      <Paper className={styles.PaperContainer} elevation={6}>
        <Typography className={styles.PaperMetods} variant="h4">
          По Україні:
        </Typography>
        <Typography variant="body1">
          По Україні доставка здійснюється компанією «Нова пошта» на зручне Вам
          відділення. Термін доставки, як правило, 1-2 дні з моменту відправки.
          Вартість доставки сертифіката "Новою поштою" - згідно з тарифами
          перевізника.
        </Typography>
      </Paper>

      <Paper className={styles.PaperContainer} elevation={6}>
        <Typography className={styles.PaperMetods} variant="h4">
          Способи оплати при самовивозі:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">Готівкою в Центрі</Typography>
          </li>
          <li>
            <Typography variant="body1">Безготівкова оплата </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Картою Visa і MasterCard (оплата на сайті через систему
              "Wayforpay")
            </Typography>
          </li>
        </ul>
      </Paper>
    </Box>
  );
};

export default Delivery;
