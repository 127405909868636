import React from "react";
import { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import CardActionArea from "@material-ui/core/CardActionArea";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Flippy, { FrontSide, BackSide } from "../../node_modules/react-flippy";
import { ShoppingCart } from "@material-ui/icons";
import { Box, Divider } from "@material-ui/core";
import "../styles/Home.module.css";
import { connect } from "react-redux";
import { addBasket } from "../actions/addAction";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    height: "100%",
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-around",
    alignContent: "center",
    margin: "20px 0",
    alignItems: "center",
  },
  cardMedia: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },

  cardContainer: {
    perspective: 600,
    width: 350,
    height: 390,
    boxShadow: "5px 5px 5px 5px #f8f8f8",
    "&:hover": {
      boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 5px 10px rgba(0,0,0,0.05)",
    },
  },
  iconBtn: {
    width: 50,
    height: 50,
    border: 0,
    outline: 0,
    color: "#fff",
    backgroundColor: [theme.palette.primary.main],
    borderRadius: 12,
    display: "block",
    overflow: "hidden",
    position: "relative",
    "&:hover": {
      background: [theme.palette.primary.dark],

      boxShadow: "0 6px 6px rgba(0,0,0,0.14), 10px 10px 10px rgba(0,0,0,0.10)",
    },
  },
  paperContainer: {
    margin: "0 auto",
    width: 350,
    height: 370,
  },
  oldPrice: {
    fontWeight: 400,
    textDecoration: "line-through",
    fontSize: "22px",
    color: "rgba(0, 0, 0, 0.87)",
  },
  discountPrice: {
    fontSize: "26px",
    fontWeight: 600,
    color: [theme.palette.primary.main],
  },
  cardSubtitle: {
    height: 50,
  },
  cardTitle: {
    height: 70,
  },
}));

const ProductFlip = (props) => {
  console.log(props);
  const classes = useStyles();
  const cardInfo = [
    {
      name: "verhnjaGubaWoman",
      image: "images/verhnjaGubaWoman.jpg",
      title: "«Верхня губа» - для жінок",
      subtitle:
        "Сертифікат на послугу лазерної епіляції «Золотий Стандарт» зони над верхньою губою.",
      paragraphFirst:
        "Лазерна епіляція – ефективна процедура, яка допоможе впоратися з проблемою вростання волосся, його надмірного росту, зробить шкіру гладенькою і шовковистою надовго. Сеанс проводиться на апараті Lumenis LightSheer.",
      paragraphSecond:
        "Перед процедурою необхідно утриматися від засмаги (14 днів), не висмикувати волосся в зоні обробки (14 днів) та не протирати область спиртовмісними засобами (3 дні).",
      warningsTitle: "Протипоказання:",
      warningsListItem1: "Вагітність, період лактації;",
      warningsListItem2: "Онкологічні захворювання;",
      warningsListItem3: "Цукровий діабет в стадії декомпенсації;",
      warningsListItem4:
        "Соматичні захворювання шкіри (псоріаз, екзема, герпес).",
      warningsParagraph:
        "Лазерна епіляція не передбачена для видалення білого і сивого волосся. В цьому випадку показана наноепіляція.",
      oldPrice: "230 грн",
      discountPrice: "161 грн",
    },
    {
      name: "pahvuWoman",
      image: "images/pahvuWoman.jpg",
      title: "«Пахви» - для жінок",
      subtitle:
        "Сертифікат на послугу лазерної епіляції «Золотий Стандарт» зони пахв.",
      paragraphFirst:
        "Лазерна епіляція – єдиний на сьогодні метод, який після кількох сеансів дозволяє видалити до 90% волосся назавжди. Процедура проводиться на апараті від американської компанії Lumenis. Це оригінальне обладнання, що неодноразово довело свою ефективність.",
      paragraphSecond:
        "Епіляція лазером допоможе забути про вростання волосся, чорні цятки та щоденне гоління. Сеанс не потребує особливої підготовки. Перед відвідуванням Центру потрібно поголити зону обробки. 2 тижні перед процедурою не засмагати і не висмикувати волоски.",
      warningsTitle: "Протипоказання:",
      warningsListItem1: "Вагітність, період лактації;",
      warningsListItem2: "Онкологічні захворювання;",
      warningsListItem3: "Цукровий діабет в стадії декомпенсації;",
      warningsListItem4:
        "Соматичні захворювання шкіри (псоріаз, екзема, герпес).",
      warningsParagraph:
        "Лазерна епіляція не передбачена для видалення білого та сивого волосся. В данному випадку показана наноепіляція.",
      oldPrice: "390 грн",
      discountPrice: "273 грн",
    },
    {
      name: "nogiWoman",
      image: "images/nogiWoman.jpg",
      title: "«Ноги повністю» - для жінок",
      subtitle:
        "Сертифікат на послугу лазерної епіляції «Золотий Стандарт» зони «ноги повністю» (гомілки з колінами, стегна, пальці ніг). Сертифікат діє лише для жінок.",
      paragraphFirst:
        "Лазерна епіляція допоможе забути про зайве волосся назавжди. Для того, щоб підготуватись до процедури, потрібно поголити необхідну зону. Також 2 тижні до відвідування Центру не можна засмагати. Видалення волосся лазером у нашому Центрі проводиться на діодному лазері Lumenis LightSheer.",
      warningsTitle: "Протипоказання:",
      warningsListItem1: "Вагітність, період лактації;",
      warningsListItem2: "Онкологічні захворювання;",
      warningsListItem3: "Цукровий діабет в стадії декомпенсації;",
      warningsListItem4:
        "Соматичні захворювання шкіри (псоріаз, екзема, герпес).",
      warningsParagraph:
        "Лазерна епіляція не передбачена для видалення білого та сивого волосся. В данному випадку показана наноепіляція.",
      oldPrice: "1900 грн",
      discountPrice: "1330 грн",
    },
    {
      name: "faceMen",
      image: "images/faceMen.jpg",
      title: "«Обличчя» - для чоловіків",
      subtitle:
        "Сертифікат на послугу лазерної епіляції «Золотий Стандарт» зони обличчя. Діє лише для чоловіків.",
      paragraphFirst:
        "Перед процедурою не можна засмагати 2 тижні і 3 дні протирати зону засобами зі спиртом. Також за 4-8 годин до сеансу зону необхідно поголити. У нашому Центрі для проведення процедури використовується апарат Lumenis LightSheer.",
      paragraphSecond:
        "Після проходження курсу лазерної епіляції гарантовано усувається до 90% волосся. Минає подразнення, почервоніння, фолікуліт.",
      warningsTitle: "Протипоказання:",
      warningsListItem1: "Онкологічні захворювання;",
      warningsListItem2: "Цукровий діабет в стадії декомпенсації;",
      warningsListItem3:
        "Соматичні захворювання шкіри (псоріаз, екзема, герпес).",
      warningsListItem4: "Вагітність, період лактації;",
      warningsParagraph:
        "Лазерна епіляція не передбачена для видалення білого та сивого волосся. В данному випадку показана наноепіляція.",
      oldPrice: "810 грн",
      discountPrice: "567 грн",
    },
    {
      name: "bikiniMen",
      image: "images/bikiniMen.jpg",
      title: "«Бікіні глибоке» - для чоловіків",
      subtitle:
        "Сертифікат на послугу лазерної епіляції методом «Золотий Стандарт». Діє на зону «бікіні глибоке чоловіче».",
      paragraphFirst:
        "Лазерна епіляція допоможе Вам забути про подразнення після гоління, вростання волосся, його надмірний ріст. Після курсу процедур усувається до 90% усіх волосків. У нашому Центрі процедура проводиться на апараті Lumenis LightSheer.",
      paragraphSecond:
        "Обладнання має вбудовану систему охолодження, що робить епіляцію комфортнішою. Щоб підготуватись до сесії, потрібно поголити зону за кілька годин до відвідування Центру, 2 тижні не засмагати та 3 дні не протирати область обробки засобами зі спиртом. Сертифікат діє на видалення волосся по всій інтимній області, включаючи мошонку.",
      warningsTitle: "Протипоказання:",
      warningsListItem2: "Онкологічні захворювання;",
      warningsListItem3: "Цукровий діабет в стадії декомпенсації;",
      warningsListItem4:
        "Соматичні захворювання шкіри (псоріаз, екзема, герпес).",
      warningsParagraph:
        "Лазерна епіляція не передбачена для видалення білого та сивого волосся. В данному випадку показана наноепіляція.",
      oldPrice: "1210 грн",
      discountPrice: "847 грн",
    },
    {
      name: "pahvuMen",
      image: "images/pahvuMen.jpg",
      title: "«Пахви» - для чоловіків",
      subtitle:
        "Сертифікат на послугу лазерної епіляції методом «Золотий Стандарт» зони пахв. Діє лише для чоловіків.",
      paragraphFirst:
        "Лазерна епіляція – ефективний спосіб видалення волосся. З її допомогою можна видалити до 90% рослинності назавжди. Метод не провокує вростання, не викликає почервоніння і подразнення шкіри. Процедура проводиться на апараті Lumenis LightSheer (США). Перед відвідуванням Центру область пахв потрібно поголити. Заборонено протирати зону засобами зі спиртом за 3 дні до сеансу. 2 тижні до процедури не можна засмагати.",
      warningsTitle: "Противопоказания:",
      warningsListItem2: "Онкологические заболевания;",
      warningsListItem3: "Сахарный диабет в стадии декомпенсации;",
      warningsListItem4:
        "Соматические заболевания кожи (псориаз, экзема, герпес).",
      warningsParagraph:
        "Лазерная эпиляция не предназначена для удаления белых и седых волос. В данном случае показана наноэпиляция.",
      oldPrice: "470 грн",
      discountPrice: "329 грн",
    },
  ];
  const ref = useRef();
  return (
    <>
      <div className={classes.root}>
        {cardInfo.map((card, id, index) => {
          return (
            <div key={id}>
              <Card className={classes.cardContainer}>
                <Flippy
                  key={index}
                  ref={ref}
                  className={classes.cardContainer}
                  flipOnHover={true} // default false
                  flipOnClick={true} // default false
                  flipDirection="horizontal" // horizontal or vertical
                >
                  <FrontSide>
                    <CardActionArea style={{ height: 360 }}>
                      <CardHeader
                        title={card.title}
                        className={classes.cardTitle}
                      />
                      <CardMedia
                        className={classes.cardMedia}
                        image={card.image}
                      />
                      <CardContent>
                        <Typography
                          variant="body2"
                          color="textPrimary"
                          className={classes.cardSubtitle}
                          component="p"
                        >
                          {card.subtitle}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </FrontSide>
                  <BackSide style={{ overflow: "auto" }}>
                    <Typography paragraph variant="body2">
                      {card.paragraphFirst}
                    </Typography>
                    <Typography paragraph variant="body2">
                      {card.paragraphSecond}
                    </Typography>
                    <Divider />
                    <Typography color="textSecondary" variant="body1">
                      {card.warningsTitle}
                    </Typography>
                    <Typography
                      paragraph
                      color="textSecondary"
                      variant="body2"
                      component="ul"
                    >
                      <li>{card.warningsListItem1}</li>
                      <li>{card.warningsListItem2}</li>
                      <li>{card.warningsListItem3}</li>
                      <li>{card.warningsListItem4}</li>
                    </Typography>
                    <Typography paragraph variant="body2">
                      {card.warningsParagraph}
                    </Typography>
                  </BackSide>
                </Flippy>
              </Card>
              <div>
                <CardActions
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                >
                  <div className={classes.oldPrice}>{card.oldPrice}</div>
                  <div className={classes.discountPrice}>
                    {card.discountPrice}
                  </div>
                  <Button
                    onClick={() => props.addBasket(`${card.name}`)}
                    className={classes.iconBtn}
                    classes={{
                      root: classes.root,
                    }}
                    style={{
                      height: 50,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <ShoppingCart />
                  </Button>
                </CardActions>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default connect(null, { addBasket })(ProductFlip);
