import React, { useState } from "react";
import {
  List,
  ListItem,
  IconButton,
  ListItemText,
  makeStyles,
  Drawer,
  Box,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { SiPluscodes } from "react-icons/si";
import { IoMdCart } from "react-icons/io";

const DrawerComponent = () => {
  const useStyles = makeStyles((theme) => ({
    drawerContainer: { width: "100%", margin: "0 auto" },
    iconButtonContainer: {
      color: "#222",
      background: "#f8f8f8",
    },

    menuIconToggle: {
      background: "transparent",
      color: "rgba(228, 19, 19, .8)",
      fontSize: "2.5rem",
    },
  }));

  const [openDrawer, setOpenDrawer] = useState(false);

  const classes = useStyles();
  return (
    <>
      <Drawer
        anchor="right"
        classes={{ paper: classes.drawerContainer }}
        onClose={() => setOpenDrawer(false)}
        open={openDrawer}
        onMouseEnter={() => setOpenDrawer(true)}
      >
        <List>
          <ListItem divider button onClick={() => setOpenDrawer(false)}>
            <ListItemText style={{ textDecoration: "none", color: "blue" }}>
              <Link to="/">Головна</Link>
            </ListItemText>
          </ListItem>

          <ListItem divider button onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/delivery">Доставка</Link>
            </ListItemText>
          </ListItem>

          <ListItem divider button onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/policy">Політика</Link>
            </ListItemText>
          </ListItem>
        </List>
      </Drawer>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 15,
        }}
      >
        <IconButton
          className={classes.iconButtonContainer}
          onClick={() => setOpenDrawer(!openDrawer)}
        >
          <SiPluscodes className={classes.menuIconToggle} />
        </IconButton>
        <IconButton className={classes.iconButtonContainer}>
          <Link to="/cart">
            <IoMdCart className={classes.menuIconToggle} />
          </Link>
        </IconButton>
      </Box>

      {/* Since this is inside our toolbar we can push it to the end of the toolbar */}
    </>
  );
};

export default DrawerComponent;
