import { INCREASE_QUANTITY, DECREASE_QUANTITY, CLEAR_PRODUCTS } from "./types";

export const productQuantity = (action, title) => {
  return (dispatch) => {
    console.log("Inside product quantity");
    console.log("The action is: ", action);
    console.log("The product name is: ", title);

    dispatch({
      type: action === "increase" ? INCREASE_QUANTITY : DECREASE_QUANTITY,
      payload: title,
    });
  };
};

export const clearProducts = (title) => {
  return (dispatch) => {
    console.log("Inside clear products");
    console.log("Product Name", title);

    dispatch({
      type: CLEAR_PRODUCTS,
      payload: title,
    });
  };
};
