import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { PhoneInTalk, ScheduleRounded } from "@material-ui/icons";
import { Link } from "react-router-dom";
import stylesStyle from "../styles/Home.module.css";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    display: "flex",
    margin: "0 auto",
    flexFlow: "row wrap",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    [theme.breakpoints.down("xs")]: {
      flexFlow: "column wrap",
    },
  },
  centerContainerInfo: {
    borderRadius: "15px",
    maxWidth: "100%",
    margin: "0 auto",
    minWidth: 330,
  },
  rightContainerMap: {
    height: 350,
    background: "rgba(255, 255, 255, 0.72)",
    boxShadow: "24px 24px 28px rgba(24, 37, 56, 0.04)",
    borderRadius: "20px",
    width: "100%",
  },
  boxContainer: {
    background: "rgba(255, 255, 255, 0.72)",
    boxShadow: "48px 24px 48px rgba(24, 37, 56, 0.04)",
    borderRadius: "20px",
    maxWidth: 340,
    height: 350,
  },
  mainHeading: {
    color: "#444",
    fontWeight: 400,
    fontSize: 30,
    textAlign: "center",
  },
  mainHeading2: {
    color: "#444",
    fontWeight: 400,
    fontSize: 30,
    marginLeft: 40,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      textAlign: "center",
    },
  },
  subHeadings: {
    color: "#EC0D18",
    fontSize: 26,
  },
  mainInfo: { marginLeft: 20, padding: "0.8rem" },

  mainHeadingRight: {
    color: "#444",
    fontWeight: 400,
    fontSize: 40,
    textAlign: "center",
  },
  rightContainerContainer: {
    width: 750,
  },

  socialHrefsYoutube: {},
}));

const ContactBlock = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.gridContainer}>
      <Grid item xs sm={6} md={4} className={classes.centerContainerInfo}>
        <Typography paragraph className={classes.mainHeading2}>
          Контактна інформація
        </Typography>
        <Box className={classes.boxContainer}>
          <div className={classes.mainInfo}>
            <Typography paragraph className={classes.subHeadings}>
              Телефони:
            </Typography>
            <Typography style={{ display: "flex", alignItems: "center" }}>
              <PhoneInTalk style={{ color: "#444" }} fontSize="small" />
              <span>(093) 177-1611, (093) 177-0636</span>
            </Typography>
          </div>
          <div className={classes.mainInfo}>
            <Typography paragraph className={classes.subHeadings}>
              Графік роботи центрів:
            </Typography>
            <Typography style={{ display: "flex", alignItems: "center" }}>
              <ScheduleRounded style={{ color: "#444" }} fontSize="small" />
              <span>Пн - Вс: с 9.00 до 21.00</span>
            </Typography>
            <Typography>
              <span style={{ marginLeft: 20 }}>Без перерв, без вихідних</span>
            </Typography>
          </div>
          <div className={classes.mainInfo}>
            <Typography paragraph className={classes.subHeadings}>
              Соціальні мережі:
            </Typography>
            <Typography
              align="left"
              style={{
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <Link
                to="https://www.facebook.com/lazerhouseukraine/"
                target="_blank"
              >
                <span className={stylesStyle.socialHrefsFacebook} />
              </Link>
              <Link
                to="https://www.instagram.com/lazerhouseukraine/"
                target="_blank"
              >
                <span className={stylesStyle.socialHrefsInsta} />
              </Link>
              <Link
                to="https://www.youtube.com/channel/UCOwEhF7hB8mKewYgrieLmfw/videos"
                target="_blank"
              >
                <span className={stylesStyle.socialHrefsYoutube} />
              </Link>
            </Typography>
          </div>
        </Box>
      </Grid>
      <Grid item xs sm={6} md={8} className={classes.rightContainerContainer}>
        <Typography paragraph className={classes.mainHeading}>
          Карта наших центрів
        </Typography>
        <iframe
          className={classes.rightContainerMap}
          src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d162795.40245713122!2d30.419418124751033!3d50.391283034233844!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1zbWFwIGdvb2dsZSDRhtC10L3RgtGA0Ysg0LvQsNC30LXRgCDRhdCw0YPRgQ!5e0!3m2!1sen!2sua!4v1624880717326!5m2!1sen!2sua"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </Grid>
    </Grid>
  );
};

export default ContactBlock;
