import React from "react";
import { Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  typoHeading: {
    fontSize: "28px",
    textDecoration: "underline",
    color: "#222",
  },
  typoParagraph: {
    color: "#555",
    fontSize: "18px",
  },
}));

const Policy = () => {
  const classes = useStyles();
  return (
    <Container>
      <div>
        <Typography paragraph className={classes.typoHeading} variant="h5">
          Які умови повернення товару?
        </Typography>
        <Typography
          paragraph
          variant="subtitle1"
          className={classes.typoParagraph}
        >
          На підставі Закону України «Про захист прав споживачів» №1023-XII від
          12.05.1991, сертифікати на послуги не підлягають поверненню або
          обміну. «Споживач має право обміняти непродовольчий товар належної
          якості на аналогічний у продавця, у якого він був придбаний, якщо
          товар не задовольняє його за формою, габаритами, фасоном, кольором,
          розміром або з інших причин не може бути використаний ним за
          призначенням».
        </Typography>
        <Typography paragraph className={classes.typoHeading} variant="h5">
          Які умови повернення товару?
        </Typography>
        <Typography variant="subtitle1" className={classes.typoParagraph}>
          Сертифікат не є платіжним засобом або платіжним документом.
          <br />
          Грошовий еквівалент Сертифіката не виплачується.
          <br />
          Сертифікат не підлягає обміну і/або поверненню, зокрема на інші
          Сертифікати.
          <br />
          Сертифікат може бути використаний лише на послугу, яка в ньому
          зазначена.
          <br />
          Сертифікат можна використати тільки один раз та тільки в одному Центрі
          «Лазерхауз».
          <br />
          Після закінчення терміну дії Сертифікат не підлягає поверненню та не
          приймається для обміну.
          <br />
          Сертифікат на послугу дійсний протягом 2х років з моменту його
          придбання.
          <br />
          Організатор не несе відповідальності за невикористання споживачем
          (Обдарованим) Сертифіката протягом терміну дії Сертифіката
          (несвоєчасне звернення за використанням Сертифіката, звернення за
          використанням Сертифіката після закінчення терміну дії Сертифіката).
        </Typography>
        <Typography paragraph className={classes.typoHeading} variant="h5">
          Правила використання сертифіката на знижку
        </Typography>
        <Typography variant="subtitle1" className={classes.typoParagraph}>
          Сертифікат на знижку не є платіжним засобом або платіжним документом.
          <br />
          Сертифікат на знижку може бути наданий лише в електронному вигляді.
          <br />
          Сертифікат на знижку не має грошового еквіваленту.
          <br />
          Сертифікат може бути використаний лише на послуги, які в ньому
          зазначені.
          <br />
          Сертифікат можна використати тільки один раз та тільки в одному Центрі
          «Лазерхауз».
          <br />
          Після закінчення терміну дії Сертифіката отримати знижку за ним не
          можна.
        </Typography>
        <Typography paragraph className={classes.typoHeading} variant="h5">
          Повернення коштів
        </Typography>
        <Typography
          paragraph
          variant="subtitle1"
          className={classes.typoParagraph}
        >
          Повернення коштів за сертифікат не передбачено, лише обмін його на
          послугу/знижку, яка в ньому зазначена.
        </Typography>
      </div>
    </Container>
  );
};

export default Policy;
