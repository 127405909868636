import { createTheme } from "@material-ui/core";

const theme = createTheme({
  palette: {
    background: {
      default: "#fff",
    },
    primary: {
      main: "#E30000",
      dark: "#b00000",
    },
    text: {
      secondary: "#E30000",
    },
  },
  typography: {
    fontFamily: "Roboto Condensed",
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": ["Roboto Condensed"],
      },
    },
  },
});
export default theme;
