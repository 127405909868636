// import Cart from "../../pages/cart/cart";
import React, { useState, useEffect } from "react";
import {
  List,
  makeStyles,
  ListItem,
  ListItemText,
  Toolbar,
  useMediaQuery,
  useTheme,
  Container,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import DrawerComponent from "./DrawerComponent/DrawerComponent";
import { ShoppingBasket } from "@material-ui/icons";
import { connect } from "react-redux";
import { getNumbers } from "../../actions/getAction";
import { IoMdCart } from "react-icons/io";

const useStyles = makeStyles((theme) => ({
  logo: {
    fontSize: "1.2rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.2rem",
    },
  },
  acount: {
    "&:hover": {},
  },
  tabsContainer: {
    listStyleType: "none",
    display: "flex",
    margin: "auto",
  },
  iconLogo: {
    fontSize: "2rem",
  },
  icons: {
    fontSize: "1.4rem",
  },
  linkItem: {
    display: "flex",
    color: [theme.palette.primary.main],
    fontSize: 20,
    textDecoration: "none",
    fontWeight: 600,
    "&:hover": {
      color: [theme.palette.primary.dark],
    },
  },
  toolBarComp: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  cartIcon: {
    fontSize: "1.8rem",
  },
}));

const Navbar = (props) => {
  console.log(props);

  useEffect(() => {
    getNumbers();
    return () => {};
  }, []);

  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);

  const classes = useStyles();

  const theme = useTheme();

  const isMatch = useMediaQuery(theme.breakpoints.down("sm"));

  //Functions
  const handleClickTab = (e, newValue) => {
    //The second value contains the current index
    setValue(newValue);
  };

  const handleOpenMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Container>
      <div>
        <Toolbar className={classes.toolBarComp}>
          {isMatch ? (
            <>
              <DrawerComponent />
            </>
          ) : (
            <>
              <List className={classes.tabsContainer} value={value}>
                <ListItem button onChange={handleClickTab}>
                  <ListItemText>
                    <Link className={classes.linkItem} to="/">
                      Головна
                    </Link>
                  </ListItemText>
                </ListItem>

                <ListItem button onChange={handleClickTab}>
                  <ListItemText>
                    <Link className={classes.linkItem} to="/delivery">
                      Доставка
                    </Link>
                  </ListItemText>
                </ListItem>

                <ListItem button onChange={handleClickTab}>
                  <ListItemText>
                    <Link className={classes.linkItem} to="/policy">
                      Політика
                    </Link>
                  </ListItemText>
                </ListItem>
                <ListItem button onChange={handleClickTab}>
                  <ListItemText>
                    <Link className={classes.linkItem} to="/cart">
                      <IoMdCart className={classes.cartIcon} />
                      {/* {props.basketProps.basketNumbers} */}
                    </Link>
                  </ListItemText>
                </ListItem>
              </List>
            </>
          )}
        </Toolbar>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  basketProps: state.basketState,
});

export default connect(mapStateToProps, { getNumbers })(Navbar);
