import {
  ADD_PRODUCT_BASKET,
  GET_NUMBERS_BASKET,
  DECREASE_QUANTITY,
  INCREASE_QUANTITY,
  CLEAR_PRODUCTS,
} from "../actions/types";

const initialState = {
  basketNumbers: 0,
  cartCost: 0,
  products: {
    verhnjaGubaWoman: {
      title: "«Верхня губа» - для жінок",
      tagName: "verhnjaGubaWoman",
      price: 161.0,
      numbers: 0,
      inCart: false,
    },
    pahvuWoman: {
      title: "«Пахви» - для жінок",
      tagName: "pahvuWoman",
      price: 273.0,
      numbers: 0,
      inCart: false,
    },
    nogiWoman: {
      title: "«Ноги повністю» - для жінок",
      tagName: "nogiWoman",
      price: 1330.0,
      numbers: 0,
      inCart: false,
    },
    faceMen: {
      title: "«Обличчя» - для чоловіків",
      tagName: "faceMen",
      price: 567.0,
      numbers: 0,
      inCart: false,
    },
    bikiniMen: {
      title: "«Бікіні глибоке» - для чоловіків",
      tagName: "bikiniMen",
      price: 847.0,
      numbers: 0,
      inCart: false,
    },
    pahvuMen: {
      title: "«Пахви» - для чоловіків",
      tagName: "pahvuMen",
      price: 329.0,
      numbers: 0,
      inCart: false,
    },
  },
};

export default (state = initialState, action) => {
  let productSelected = "";
  switch (action.type) {
    case ADD_PRODUCT_BASKET:
      productSelected = { ...state.products[action.payload] };
      productSelected.numbers += 1;
      productSelected.inCart = true;

      console.log(productSelected);
      return {
        ...state,
        basketNumbers: state.basketNumbers + 1,
        cartCost: state.cartCost + state.products[action.payload].price,
        products: {
          ...state.products,
          [action.payload]: productSelected,
        },
      };

    case GET_NUMBERS_BASKET:
      return {
        ...state,
      };
    case INCREASE_QUANTITY:
      // state.product['pahvuWoman']
      productSelected = { ...state.products[action.payload] };
      productSelected.numbers += 1;

      return {
        ...state,
        cartCost: state.cartCost + state.products[action.payload].price,
        products: {
          ...state.products,
          [action.payload]: productSelected,
        },
      };
    case DECREASE_QUANTITY:
      productSelected = { ...state.products[action.payload] };
      let newCartCost = 0;

      if (productSelected.numbers === 0) {
        productSelected.numbers = 0;
        newCartCost = state.cartCost;
      } else {
        productSelected.numbers -= 1;
        newCartCost = state.cartCost - state.products[action.payload].price;
      }

      return {
        ...state,
        cartCost: newCartCost,
        products: {
          ...state.products,
          [action.payload]: productSelected,
        },
      };
    case CLEAR_PRODUCTS:
      productSelected = { ...state.products[action.payload] };
      let numbersBackup = productSelected.numbers;
      (productSelected.numbers = 0), (productSelected.inCart = false);
      return {
        ...state,
        cartCost: state.cartCost - numbersBackup * productSelected.price,
        products: {
          ...state.products,
          [action.payload]: productSelected,
        },
      };
    default:
      return state;
  }
};
