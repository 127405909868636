import Layout from "../src/components/Layout/Layout";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import Home from "./Home";
import Delivery from "./Delivery";
import Cart from "./Cart";
import Policy from "./Policy";
import { CssBaseline } from "@material-ui/core";
import "./styles/Home.module.css";
import { Provider } from "react-redux";
import store from "./store";
import theme from "./theme";

export default function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router>
          <CssBaseline />
          <Layout>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/delivery" component={Delivery} />
              <Route path="/policy" component={Policy} />
              <Route path="/cart" component={Cart} />
            </Switch>
          </Layout>
        </Router>
      </ThemeProvider>
    </Provider>
  );
}
