import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Navbar from "../navbar/Navbar";
import styles from "../../styles/Home.module.css";

const Layout = ({ children }) => {
  return (
    <>
      <div className={styles.container}>
        <Header />
        <Navbar />
        <div style={{ marginBottom: "100px", height: "100%" }}>{children}</div>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
