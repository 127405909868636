import React from "react";
import { Grid, Box, Container } from "@material-ui/core";
import clsx from "clsx";
import styles from "../../styles/Home.module.css";

const Header = () => {
  return (
    <>
      <Container>
        <Grid container className={clsx(styles.row, styles.header)}>
          <Box className={clsx(styles.col, styles.headerCol)}>
            <a href="/" className={styles.logo}></a>
          </Box>

          <Box className={clsx(styles.col, styles.headerCol, styles.hidden890)}>
            <Box>
              <span className={clsx(styles.headerTitle, styles.iconClock)}>
                з <b>09:00</b> до <b>21:00</b>
                <span className={styles.subTitle}>без перерви та вихідних</span>
              </span>
            </Box>
          </Box>
          <Box className={clsx(styles.col, styles.headerCol)}>
            <div>
              <span className={clsx(styles.headerTitle, styles.iconPhone)}>
                <b>(093)177-1611</b>
              </span>
              <span className={clsx(styles.subTitle, styles.colorRed)}>
                63 Центри по всій Україні
              </span>
            </div>
          </Box>
        </Grid>
      </Container>
    </>
  );
};

export default Header;
