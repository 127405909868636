import React from "react";
import { Container, Grid } from "@material-ui/core";
import clsx from "clsx";
import styles from "../../styles/Home.module.css";

const Footer = (props) => {
  return (
    <Container disableGutters className={styles.container}>
      <footer className={styles.footer}>
        <Grid
          container
          justifyContent="space-around"
          alignContent="center"
          alignItems="center"
        >
          <Grid item className={clsx(styles.col, styles.colorWhite)}>
            2007-2021 © Лазерхауз
          </Grid>
          <Grid
            item
            className={clsx(styles.col, styles.colorWhite, styles.textCenter)}
          >
            Ліцензія МОЗ України АЕ №459488 від 04.09.2014
          </Grid>
          <div className={clsx(styles.col, styles.colorWhite, styles.textEnd)}>
            <a href="#" target="_blank">
              Політика конфіденційності
            </a>
          </div>
        </Grid>
      </footer>
    </Container>
  );
};

export default Footer;
